export const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN
export const auth0ClientId = process.env.GATSBY_AUTH0_CLIENTID
export const auth0RedirectURI = process.env.GATSBY_AUTH0_REDIRECT_URI
export const auth0Audience = process.env.GATSBY_AUTH0_AUDIENCE
export const apiEndpoint = process.env.GATSBY_API_ENDPOINT
export const siteUrl = process.env.GATSBY_SITE_URL
export const DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS = 60
export const DEFAULT_POPUP_CONFIG_OPTIONS = {
  timeoutInSeconds: DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS,
}
