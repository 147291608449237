import fetchFn from "cross-fetch"
import { toCamel } from "convert-keys"
import { auth0 } from "./auth"
import { apiEndpoint } from "../configs"
import { ResponseError, LoginRequired } from "../errors"
import { withSearch, getPathname } from "./location"
import debug from "./debug"
async function fetch(endpoint, { body, ...customConfig } = {}) {
  const headers = { "Content-Type": "application/json" }
  const config = {
    method: body ? "POST" : "GET",
    mode: "cors",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }
  debug("fetch url: %s , config: %o", endpoint, JSON.stringify(config, null, 2))
  return fetchFn(endpoint, config).then(async response => {
    const data = await response.json()
    if (response.ok) {
      debug(
        "fetch response: %s , data: %o",
        endpoint,
        JSON.stringify(data, null, 2)
      )
      return data
    } else {
      const message = data.message || response.statusText
      throw new ResponseError(message, {
        status: response.status,
        statusText: response.statusText,
        data: data,
      })
    }
  })
}
function getApiFecher(fetchOptions = {}) {
  return async function fetchApi(path, options = {}) {
    const autoLogin = options.autoLogin || false
    delete options.autoLogin
    const shouldUseAuth =
      options.shouldUseAuth !== undefined
        ? options.shouldUseAuth
        : fetchOptions.shouldUseAuth !== undefined
        ? fetchOptions.shouldUseAuth
        : true
    try {
      const finalUrl = `${apiEndpoint}${path}`
      if (shouldUseAuth !== false) {
        const token = await auth0.getToken()
        options.headers = Object.assign({}, options.headers, {
          Authorization: `Bearer ${token}`,
        })
      }

      const data = await fetch(finalUrl, options)
      return toCamel(data)
    } catch (error) {
      if (error.error === "login_required") {
        if (autoLogin) {
          const appState = { targetUrl: withSearch(getPathname()) }
          debug("appState: %o", appState)
          return await auth0.loginWithRedirect({ appState })
        } else {
          throw new LoginRequired(error)
        }
      }
      if (error.data) {
        error.data = toCamel(error.data)
      }
      throw error
    }
  }
}
function fetchApi(path, options = {}) {
  return getApiFecher()(path, options)
}

export { fetch, fetchApi, getApiFecher }
export default fetch
