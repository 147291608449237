import { css } from "styled-components"

const ALIGN_SELF_MAP = {
  center: "center",
  end: "flex-end",
  start: "flex-start",
  stretch: "stretch",
}

// Returns the specific color that should be used according to the theme.
// If 'dark' is supplied, it takes precedence over 'theme.dark'.
// Can return undefined.
export const normalizeColor = (color, theme, dark) => {
  const colorSpec =
    theme.global && theme.global.colors[color] !== undefined
      ? theme.global.colors[color]
      : color
  // If the color has a light or dark object, use that
  let result = colorSpec
  if (colorSpec) {
    if (
      (dark === true || (dark === undefined && theme.dark)) &&
      colorSpec.dark !== undefined
    ) {
      result = colorSpec.dark
    } else if (
      (dark === false || !theme.dark) &&
      colorSpec.light !== undefined
    ) {
      result = colorSpec.light
    }
  }
  // allow one level of indirection in color names
  if (result && theme.global && theme.global.colors[result] !== undefined) {
    result = normalizeColor(result, theme, dark)
  }

  return result
}

export const genericStyles = css`
  ${props => {
    return props.alignSelf && `align-self: ${ALIGN_SELF_MAP[props.alignSelf]};`
  }}
  ${props => props.gridArea && `grid-area: ${props.gridArea};`}
  ${props =>
    props.margin &&
    props.theme.global &&
    edgeStyle(
      "margin",
      props.margin,
      props.responsive,
      props.theme.global.edgeSize.responsiveBreakpoint,
      props.theme
    )}
`

export const edgeStyle = (
  kind,
  data,
  responsive,
  responsiveBreakpoint,
  theme
) => {
  const breakpoint =
    responsiveBreakpoint && theme.global.breakpoints[responsiveBreakpoint]

  if (typeof data === "string") {
    return css`
      ${kind}: ${theme.global.edgeSize[data] || data};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
        ${kind}: ${breakpoint.edgeSize[data] || data};
      `
          )
        : ""};
    `
  }
  const result = []

  const { horizontal, vertical, top, bottom, left, right } = data

  // if horizontal and vertical are equal OR all sides are equal,
  // we can just return a single css value such as padding: 12px
  // instead of breaking out by sides.
  const horizontalVerticalEqual =
    horizontal && vertical && horizontal === vertical
  const allSidesEqual =
    top && bottom && left && right && ((top === bottom) === left) === right

  if (horizontalVerticalEqual || allSidesEqual) {
    // since the values will be the same between vertical & horizontal OR
    // left, right, top, & bottom, we can just choose one
    const value = horizontalVerticalEqual ? horizontal : top
    return css`
      ${kind}: ${theme.global.edgeSize[value] || value};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
        ${kind}: ${breakpoint.edgeSize[value] || value};
      `
          )
        : ""};
    `
  }

  if (horizontal) {
    result.push(css`
      /* stylelint-disable-next-line */
      ${kind}-left: ${theme.global.edgeSize[horizontal] || horizontal};
      /* stylelint-disable-next-line */
      ${kind}-right: ${theme.global.edgeSize[horizontal] || horizontal};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
          ${kind}-left: ${breakpoint.edgeSize[horizontal] || horizontal};
          ${kind}-right: ${breakpoint.edgeSize[horizontal] || horizontal};
        `
          )
        : ""};
    `)
  }
  if (vertical) {
    result.push(css`
      /* stylelint-disable-next-line */
      ${kind}-top: ${theme.global.edgeSize[vertical] || vertical};
      /* stylelint-disable-next-line */
      ${kind}-bottom: ${theme.global.edgeSize[vertical] || vertical};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
          ${kind}-top: ${breakpoint.edgeSize[vertical] || vertical};
          ${kind}-bottom: ${breakpoint.edgeSize[vertical] || vertical};
        `
          )
        : ""};
    `)
  }
  if (top) {
    result.push(css`
      /* stylelint-disable-next-line */
      ${kind}-top: ${theme.global.edgeSize[top] || top};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
          ${kind}-top: ${breakpoint.edgeSize[top] || top};
        `
          )
        : ""};
    `)
  }
  if (bottom) {
    result.push(css`
      /* stylelint-disable-next-line */
      ${kind}-bottom: ${theme.global.edgeSize[bottom] || bottom};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
          ${kind}-bottom: ${breakpoint.edgeSize[bottom] || bottom};
        `
          )
        : ""};
    `)
  }
  if (left) {
    result.push(css`
      /* stylelint-disable-next-line */
      ${kind}-left: ${theme.global.edgeSize[left] || left};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
          ${kind}-left: ${breakpoint.edgeSize[left] || left};
        `
          )
        : ""};
    `)
  }
  if (right) {
    result.push(css`
      /* stylelint-disable-next-line */
      ${kind}-right: ${theme.global.edgeSize[right] || right};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
          ${kind}-right: ${breakpoint.edgeSize[right] || right};
        `
          )
        : ""};
    `)
  }
  if (data.start) {
    result.push(css`
      ${kind}-inline-start: ${theme.global.edgeSize[data.start] || data.start};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
          ${kind}-inline-start: ${
              breakpoint.edgeSize[data.start] || data.start
            };
        `
          )
        : ""};
    `)
  }
  if (data.end) {
    result.push(css`
      ${kind}-inline-end: ${theme.global.edgeSize[data.end] || data.end};
      ${responsive && breakpoint
        ? breakpointStyle(
            breakpoint,
            `
          ${kind}-inline-end: ${breakpoint.edgeSize[data.end] || data.end};
        `
          )
        : ""};
    `)
  }

  return result
}

export const breakpointStyle = (breakpoint, content) => css`
  @media only screen ${breakpoint.value &&
    `and (max-width: ${breakpoint.value}px)`} {
    ${content};
  }
`

const focusStyles = (props, { forceOutline, justBorder } = {}) => {
  const {
    theme: {
      global: { focus },
    },
  } = props
  if (!focus || (forceOutline && !focus.outline)) {
    const color = normalizeColor("focus", props.theme)
    if (color) return `outline: 2px solid ${color};`
    return "" // native
  }
  if (focus.outline && (!focus.border || !justBorder)) {
    if (typeof focus.outline === "object") {
      const color = normalizeColor(focus.outline.color || "focus", props.theme)
      const size = focus.outline.size || "2px"
      return `
        outline-offset: 0px;
        outline: ${size} solid ${color};
      `
    }
    return `outline: ${focus.outline};`
  }
  if (focus.shadow && (!focus.border || !justBorder)) {
    if (typeof focus.shadow === "object") {
      const color = normalizeColor(
        // If there is a focus.border.color, use that for shadow too.
        // This is for backwards compatibility in v2.
        (focus.border && focus.border.color) || focus.shadow.color || "focus",
        props.theme
      )
      const size = focus.shadow.size || "2px" // backwards compatible default
      return `
        outline: none;
        box-shadow: 0 0 ${size} ${size} ${color};
      `
    }
    return `
      outline: none;
      box-shadow: ${focus.shadow};
    `
  }
  if (focus.border) {
    const color = normalizeColor(focus.border.color || "focus", props.theme)
    return `
      outline: none;
      border-color: ${color};
    `
  }
  return "" // defensive
}
// focus also supports clickable elements inside svg
export const focusStyle = ({
  forceOutline,
  justBorder,
  skipSvgChildren,
} = {}) => css`
  ${props =>
    !skipSvgChildren &&
    `
  > circle,
  > ellipse,
  > line,
  > path,
  > polygon,
  > polyline,
  > rect {
    ${focusStyles(props)}
  }`}
  ${props => focusStyles(props, { forceOutline, justBorder })}
  ${
    !forceOutline &&
    `
  ::-moz-focus-inner {
    border: 0;
  }
  `
  }
`
