/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { ThemeProvider } from "./src/components/providers/theme"
import { Auth0Provider } from "./src/components/providers/auth"
import { LocationProvider } from "./src/components/providers/location"
import { HttpProvider } from "./src/components/providers/http"
import WrapPage from "./src/components/wrap-page"

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      <Auth0Provider>
        <HttpProvider>{element}</HttpProvider>
      </Auth0Provider>
    </ThemeProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <LocationProvider {...props}>
      <WrapPage>{element}</WrapPage>
    </LocationProvider>
  )
}
