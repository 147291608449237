import React from "react"
import { Grommet } from "grommet"
import { useTheme } from "../hooks"
export default props => {
  const { theme, themeMode } = useTheme()
  return (
    <Grommet full={true} theme={theme} themeMode={themeMode}>
      {props.children}
    </Grommet>
  )
}
