import { clone } from "./clone"
export function report() {
  if (typeof window !== "undefined" && typeof window.gtag === "function") {
    return window.gtag.apply(null, arguments)
  }
}

export function reportError(error) {
  console.error(error)
  const errorClone = clone(error)
  const message = (error && error.message) || ""
  delete errorClone.message
  return report("event", "exception", {
    description: message,
    ...errorClone,
  })
}

export function reportClick(category, label, value) {
  return report("event", "click", {
    event_category: category,
    event_label: label,
    value: value,
  })
}
