import React from "react"
import { debug } from "../../utils"
import { LocationContext } from "../../contexts"
let printCount = 0
export const LocationProvider = props => {
  if (printCount === 0) {
    printCount++
    debug("page props: %o", props)
  }
  const contextValue = {
    path: props.path,
    // origin: props.location.origin,
    originalPath: props.pageContext.intl.originalPath,
    uri: props.uri,
    location: props.location,
  }
  return (
    <LocationContext.Provider value={contextValue}>
      {props.children}
    </LocationContext.Provider>
  )
}
