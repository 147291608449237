import { Auth0Client } from "@auth0/auth0-spa-js"
import debug from "./debug"
import {
  auth0Domain,
  auth0Audience,
  auth0ClientId,
  auth0RedirectURI,
  DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS,
} from "../configs"
import { PopupTimeoutError, ResponseError } from "../errors"
import { createRandomString } from "./string"
import { Base64 } from "js-base64"
import { sha256 } from "./crypt"

class Auth0 {
  constructor(props, options = {}) {
    if (typeof window === "undefined") {
      // only use at client, not support ssr
      return
    }
    this.client = new Auth0Client(props)
    debug("init auth0 client: %o", props)
    this.options = options
    this.inited = false
    this._initCallbacks = []
    this._callbacks = []
  }
  _hanldeCallback(error, data) {
    // trigger callbacks
    this._callbacks.forEach(fn => {
      fn(error, data)
    })
    this._callbacks = []

    if (error && error.error === "login_required") {
      // trigger callbacks
      this._initCallbacks.forEach(fn => {
        fn(null)
      })
    } else {
      // trigger callbacks
      this._initCallbacks.forEach(fn => {
        fn(error, data)
      })
    }
    this._initCallbacks = []
  }
  async init() {
    return new Promise(async (resolve, reject) => {
      if (!this.inited) {
        // callback
        this._initCallbacks.push(err => {
          if (err) {
            reject(err)
          } else {
            resolve()
          }
        })

        try {
          const data = await this.client.getTokenSilently()
          this.inited = true
          this._hanldeCallback(null, data)
        } catch (error) {
          debug("auth error: %o", error)
          this.inited = true
          this._hanldeCallback(error)
        }
      } else {
        return resolve()
      }
    })
  }
  loginWithRedirect(params) {
    return new Promise((resolve, reject) => {
      if (!this.inited) {
        if (this.options.autoInit === false) {
          this.options.autoInit = true
          this.init()
        }
        // callback
        this._callbacks.push(err => {
          if (err) {
            reject(err)
          } else {
            this.client.loginWithRedirect(params).then(resolve).catch(reject)
          }
        })
      } else {
        this.client.loginWithRedirect(params).then(resolve).catch(reject)
      }
    })
  }
  getToken() {
    return new Promise((resolve, reject) => {
      if (!this.inited) {
        if (this.options.autoInit === false) {
          this.options.autoInit = true
          this.init()
        }
        // callback
        this._callbacks.push((err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      } else {
        this.client.getTokenSilently().then(resolve).catch(reject)
      }
    })
  }
}
export const auth0 = new Auth0({
  domain: auth0Domain,
  client_id: auth0ClientId,
  audience: auth0Audience,
  redirect_uri: auth0RedirectURI,
  useRefreshTokens: true,
})
const openPopup = url => {
  const width = 400
  const height = 600
  const left = window.screenX + (window.innerWidth - width) / 2
  const top = window.screenY + (window.innerHeight - height) / 2

  return window.open(
    url,
    "auth1:authorize:popup",
    `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`
  )
}
export const runPopup = (authorizeUrl, config) => {
  let popup = config.popup

  if (popup) {
    popup.location.href = authorizeUrl
  } else {
    popup = openPopup(authorizeUrl)
  }

  if (!popup) {
    throw new Error("Could not open popup")
  }

  return new Promise()((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new PopupTimeoutError(popup))
    }, (config.timeoutInSeconds || DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS) * 1000)
    window.addEventListener("message", e => {
      if (!e.data || e.data.type !== "authorization_response") {
        return
      }
      clearTimeout(timeoutId)
      popup.close()
      if (e.data.response.error) {
        return reject(new ResponseError(e.data.response))
      }
      resolve(e.data.response)
    })
  })
}
const encode = Base64.encode
const decode = Base64.decode

export const bufferToBase64UrlEncoded = input => {
  const ie11SafeInput = new Uint8Array(input)
  return Base64.encodeURI(
    window.btoa(String.fromCharCode(...Array.from(ie11SafeInput)))
  )
}
const getParams = function (
  authorizeOptions,
  state,
  nonce,
  code_challenge,
  redirect_uri
) {
  return {
    ...authorizeOptions,
    response_type: "code",
    response_mode: "query",
    state,
    nonce,
    redirect_uri: redirect_uri,
    code_challenge,
    code_challenge_method: "S256",
  }
}
const createQueryParams = params => {
  return Object.keys(params)
    .filter(k => typeof params[k] !== "undefined")
    .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&")
}
const getAuthorizeUrl = function (authorizeOptions) {
  return this._url(`/authorize?${createQueryParams(authorizeOptions)}`)
}
export const authWithPopup = async function (options = {}, config = {}) {
  const { ...authorizeOptions } = options
  console.log("authorizeOptions", authorizeOptions)

  const stateIn = encode(createRandomString())
  const nonceIn = encode(createRandomString())
  const code_verifier = createRandomString()
  const code_challengeBuffer = await sha256(code_verifier)
  const code_challenge = bufferToBase64UrlEncoded(code_challengeBuffer)

  const params = getParams(
    authorizeOptions,
    stateIn,
    nonceIn,
    code_challenge,
    options.redirect_uri || window.location.origin
  )

  const url = authorizeOptions.url

  const codeResult = await runPopup(url, {
    ...config,
    timeoutInSeconds:
      config.timeoutInSeconds ||
      options.authorizeTimeoutInSeconds ||
      DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS,
  })

  if (stateIn !== codeResult.state) {
    throw new Error("Invalid state")
  }
  console.log("codeResult", codeResult)
  return codeResult
}
