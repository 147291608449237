import urlParse from "url-parse"
import { Base64 } from "js-base64"

export const parseUrl = function (url) {
  return urlParse(url, true)
}

export const encodeAppState = function (appState) {
  return Base64.encodeURI(JSON.stringify(appState))
}

export const decodeAppState = function (appStateString) {
  return JSON.parse(Base64.decode(appStateString))
}
