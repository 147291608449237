import React, { useState } from "react"
import { grommet, defaultProps, base as baseTheme } from "grommet"
import merge from "lodash.merge"
import { ThemeContext } from "../../contexts"
export const ThemeProvider = props => {
  const [themeMode, setThemeMode] = useState("light")
  const theme = merge({}, defaultProps.theme, baseTheme, grommet, {
    global: {
      colors: {
        "app-border": "#F2F2F2",
      },
      elevation: {
        light: {
          none: "none",
          xsmall: "0px 1px 2px rgba(0, 0, 0, 0.10)",
          small: "0px 2px 4px rgba(0, 0, 0, 0.10)",
          medium: "0px 4px 8px rgba(0, 0, 0, 0.10)",
          large: "0px 8px 16px rgba(0, 0, 0, 0.10)",
          xlarge: "0px 12px 24px rgba(0, 0, 0, 0.10)",
        },
        dark: {
          none: "none",
          xsmall: "0px 2px 2px rgba(255, 255, 255, 0.20)",
          small: "0px 4px 4px rgba(255, 255, 255, 0.20)",
          medium: "0px 6px 8px rgba(255, 255, 255, 0.20)",
          large: "0px 8px 16px rgba(255, 255, 255, 0.20)",
          xlarge: "0px 12px 24px rgba(255, 255, 255, 0.20)",
        },
      },
    },
  })
  const themeContextValue = {
    theme,
    themeMode,
    setThemeMode,
  }
  return (
    <ThemeContext.Provider value={themeContextValue}>
      {props.children}
    </ThemeContext.Provider>
  )
}
