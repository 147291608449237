import React from "react"
import { SWRConfig as Provider } from "swr"
import { fetchApi } from "../../utils"

export const HttpProvider = function HttpProvider({ children }) {
  return (
    <Provider
      value={{
        fetcher: fetchApi,
      }}
    >
      {children}
    </Provider>
  )
}
