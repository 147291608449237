import React, { useState, useEffect, useRef } from "react"
import { Auth0Context } from "../../contexts"
import { parseUrl, getPathname, reportError, auth0 } from "../../utils"
import { auth0RedirectURI } from "../../configs"
export const Auth0Provider = ({ children }) => {
  const [auth0Client, setAuth0] = useState()
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const authInited = useRef()
  useEffect(() => {
    const initAuth0 = async () => {
      try {
        await auth0.init()
      } catch (error) {
        reportError(error)
      }
      setAuth0(auth0.client)
      const redirectUrlObj = parseUrl(auth0RedirectURI)
      const redirectPathname = redirectUrlObj.pathname
      const currentPathname = getPathname()
      const isCallbackPage = redirectPathname === currentPathname

      try {
        // check is callback, if callback then it's over
        if (isCallbackPage) {
          setLoading(false)
          return
        }
        const isAuthenticatedResult = await auth0.client.isAuthenticated()

        setIsAuthenticated(isAuthenticatedResult)
        if (isAuthenticatedResult) {
          const user = await auth0.client.getUser()
          setUser(user)
        }
        setLoading(false)
      } catch (error) {
        reportError(error)
      }
    }
    if (!authInited.current) {
      authInited.current = true
      initAuth0()
    }
  }, [auth0Client])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        loading,
        popupOpen,
        loginWithPopup,
        auth0Client: auth0Client,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
