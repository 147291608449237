import debug, { withDebug } from "./debug"
export * from "./intl"
export { parse, stringify } from "./query"
export {
  goDashboard,
  goHome,
  withoutPrefix,
  withSearch,
  getLocation,
  getPathname,
  getQuery,
  getOrigin,
  redirect,
  getLanguagePathPrefix,
  replace,
} from "./location"
export { sleep } from "./sleep"
export { normalizeColor, genericStyles, focusStyle } from "./style"
export { reportError, report, reportClick } from "./report"
export { clone } from "./clone"
export { debug, withDebug }
export { parseUrl, encodeAppState, decodeAppState } from "./url"
export { auth0, authWithPopup } from "./auth"
export { fetch, fetchApi, getApiFecher } from "./fetch"
