import { navigate } from "./intl"
import { parse } from "./query"
export const goHome = function () {
  navigate("/")
}
export const goDashboard = function () {
  navigate("/dashboard")
}
// get original path without prefix for use gatsby navigate
export function withoutPrefix(path) {
  // eslint-disable-next-line no-undef
  const prefixPath =
    // eslint-disable-next-line no-undef
    typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
  if (prefixPath && path.indexOf(prefixPath) === 0) {
    path = path.replace(new RegExp(`^${prefixPath}`), "/")
  }
  return normalizePath(path)
}
export function withSearch(path) {
  const location = getLocation()
  return path + location.search
}
export function getLocation() {
  return typeof window !== "undefined" ? window.location : {}
}
export function getPathname() {
  const location = getLocation()
  return location.pathname
}
export function getQuery() {
  const location = getLocation()
  return location.search ? parse(location.search) : {}
}
export function getOrigin() {
  const location = getLocation()
  return location.origin
}
function normalizePath(path) {
  return path.replace(/\/+/g, `/`)
}
export function redirect(url) {
  if (typeof window !== "undefined") {
    window.location.href = url
  }
}
export function getLanguagePathPrefix(intl) {
  const languagePrefix = intl.locale
  const defaultLanguage = intl.defaultLocale
  if (languagePrefix === defaultLanguage) {
    return ""
  } else {
    return `/${languagePrefix}`
  }
}
export function replace(url) {
  if (typeof window !== "undefined") {
    window.location.replace(url)
  }
}
